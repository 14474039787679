import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { PaginationCustom, PanelHeader, Spinner } from '../../components';
import Select from 'react-select';
import { projects } from '../../helpers/actions/pros';
import { loadStatuses } from '../../helpers/actions/projects';
import { projectSorts, orders, projectType } from '../../helpers/nomenclators';
import ProjectCard from './ProjectCard';

class MyProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      projects: [],
      count: 0,
      page: 1,
      limit: 20,
      statuses: [],
      status: null,
      search: '',
      type: null,
      sort: '_id',
      order: -1
    };
  }

  load() {
    this.setState({ loading: true });

    let { page, limit, status, search, sort, order, type } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    dispatch(
      projects({ limit, offset, status, search, sort, dir: order, type })
    )
      .then(({ data, meta }) =>
        this.setState({
          projects: data,
          ...meta,
          loading: false
        })
      )
      .catch(() => this.setState({ loading: false }));
  }

  componentDidMount() {
    let { page, limit, search } = this.state,
      { dispatch, match } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;
    let paramStatus = match.params.status ? match.params.status : null;

    Promise.all([
      dispatch(projects({ limit, offset, search, status: paramStatus })),
      dispatch(loadStatuses())
    ])
      .then(([{ data, meta }, { status }]) => {
        this.setState({
          projects: data,
          ...meta,
          statuses: status,
          status: paramStatus,
          loading: false
        });
      })
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    let { loading, projects, page, limit, count, statuses } = this.state;
    let { t } = this.props;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Card>
            <CardBody>
              <Row>
                <Col xs={12} md={4}>
                  <FormGroup>
                    <Label>
                      <Trans>Search</Trans>
                    </Label>
                    <Input
                      type="text"
                      value={this.state.search}
                      onChange={event =>
                        this.setState(
                          {
                            page: 1,
                            search: event.target.value
                          },
                          () => this.load()
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={3}>
                  <FormGroup>
                    <Label>
                      <Trans>Type</Trans>
                    </Label>
                    <Select
                      className="primary"
                      options={projectType.map(pt => ({
                        label: t(pt),
                        value: pt
                      }))}
                      value={this.state.type}
                      onChange={event =>
                        this.setState(
                          {
                            page: 1,
                            type: event ? event.value : null
                          },
                          () => this.load()
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={3}>
                  <FormGroup>
                    <Label>
                      <Trans>Status</Trans>
                    </Label>
                    <Select
                      className="primary"
                      options={statuses.map(s => ({
                        label: t(s),
                        value: s
                      }))}
                      value={this.state.status}
                      onChange={event =>
                        this.setState(
                          {
                            page: 1,
                            status: event ? event.value : null
                          },
                          () => this.load()
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={2}>
                  <FormGroup>
                    <Label>
                      <Trans>Sort</Trans>
                    </Label>
                    <Select
                      className="primary"
                      options={projectSorts.map(s => ({
                        label: t(s.label),
                        value: s.value
                      }))}
                      value={this.state.sort}
                      onChange={event =>
                        this.setState(
                          {
                            page: 1,
                            sort: event ? event.value : null
                          },
                          () => this.load()
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={6} md={2}>
                  <FormGroup>
                    <Label>
                      <Trans>Order</Trans>
                    </Label>
                    <Select
                      className="primary"
                      options={orders.map(s => ({
                        label: t(s.label),
                        value: s.value
                      }))}
                      value={this.state.order}
                      onChange={event =>
                        this.setState(
                          {
                            page: 1,
                            order: event ? event.value : null
                          },
                          () => this.load()
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          {projects.length > 0 ? (
            <div>
              <Row>
                {projects.map((project, key) => (
                  <Col key={key} xs={12} md={3}>
                    <ProjectCard project={project} />
                  </Col>
                ))}
              </Row>
              <Row>
                <Col xs={12} className={'text-center'}>
                  <PaginationCustom
                    className="display-flex justify-content-center"
                    page={page}
                    count={count}
                    maxPerPage={limit}
                    onPageChange={page =>
                      this.setState({ page }, () => this.load())
                    }
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <Row>
              <Col xs={12} md={12}>
                <Card>
                  <CardBody>
                    <p className={'text-not-found text-center'}>
                      <Trans>No projects found</Trans>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(MyProjects));
