import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Carousel,
  CarouselCaption,
  CarouselControl,
  CarouselItem
} from 'reactstrap';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { moneyFormatter } from '../../helpers/formatters';

class AchievementInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      activeIndex: 0
    };
  }

  componentWillReceiveProps() {
    let items = this.getItems();
    this.setState({ items, activeIndex: 0 });
  }

  componentWillMount() {
    let items = this.getItems();
    this.setState({ items, activeIndex: 0 });
  }

  getItems() {
    let { experience, t } = this.props;

    let items =
      experience && experience.photos && experience.photos.length
        ? experience.photos.map(photo => {
            return { src: photo.source };
          })
        : [
            {
              src: `data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa1d%20text%20%7B%20fill%3A%23555%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa1d%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22285.921875%22%20y%3D%22218.3%22%3E%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E`,
              caption: t('No Images')
            }
          ];

    return items;
  }

  next() {
    let { items, activeIndex } = this.state;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    let { items, activeIndex } = this.state;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  render() {
    let { items, activeIndex } = this.state,
      { experience } = this.props;

    return (
      <Card>
        <CardHeader className={'p-0'}>
          <Carousel
            activeIndex={activeIndex}
            pause={'hover'}
            ride={'carousel'}
            next={() => this.next()}
            previous={() => this.previous()}
          >
            {items.map(item => {
              return (
                <CarouselItem key={item.src}>
                  <div
                    className="pro-carousel-image"
                    style={{
                      backgroundImage: `url('${item.src}')`
                    }}
                  />
                  {item.caption ? (
                    <CarouselCaption
                      captionText={''}
                      captionHeader={item.caption}
                    />
                  ) : null}
                </CarouselItem>
              );
            })}
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={() => this.previous()}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={() => this.next()}
            />
          </Carousel>
        </CardHeader>
        <CardBody>
          <h6>{get(experience, 'title', ' ')}</h6>
          <h5 className="title">
            {moneyFormatter(get(experience, 'price', ''))}
          </h5>
          <p>
            <strong>
              <Trans>Description</Trans>
            </strong>
            {`: ${get(experience, 'description', '-')}`}
          </p>
          <p>
            <strong>
              <Trans>Surfaces</Trans>
            </strong>
            {`: ${get(experience, 'surface', '-')}`}
          </p>
        </CardBody>
        <CardFooter className={'pt-0 text-center'}>
          <hr className={'mt-0'} />
          <Link
            to={`/achievements/${experience._id}/details`}
            className="btn btn-info btn-sm m-1"
          >
            <Trans>Details</Trans>
          </Link>
          <Button
            className="m-1"
            color="info"
            size="sm"
            onClick={() => this.props.onEdit()}
          >
            <Trans>Edit</Trans>
          </Button>
          <Button
            className="m-1"
            color="danger"
            size="sm"
            onClick={() => this.props.onDelete()}
          >
            <Trans>Delete</Trans>
          </Button>
        </CardFooter>
      </Card>
    );
  }
}

export default connect()(translate('translations-fr')(AchievementInfo));
