import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { PanelHeader, Spinner } from '../../components';
import { one } from '../../helpers/actions/experiences';
import AchievementInfo from './AchievementInfo';
import Photos from './Photos';

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      experience: {}
    };
  }

  load() {
    this.setState({ loading: true });
    let { match, dispatch } = this.props;

    dispatch(one(match.params.id))
      .then(experience => {
        this.setState({ experience, loading: false });
      })
      .catch(() => this.setState({ loading: false }));
  }

  componentWillMount() {
    this.load();
  }

  render() {
    let { loading, experience } = this.state;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content" style={{ position: 'relative' }}>
          {loading ? <Spinner /> : null}
          <Row>
            <Col xs={12} md={3}>
              <AchievementInfo experience={experience} />
            </Col>
            <Col xs={12} md={9}>
              <Photos experience={experience} onConfirm={() => this.load()} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Details));
