import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';

class AchievementModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      experience: {}
    };
  }

  componentWillReceiveProps({ experience }) {
    this.setState({ experience });
  }

  cleanModal() {
    this.setState({
      experience: {},
      titleValid: null,
      descriptionValid: null,
      surfaceValid: null,
      priceValid: null
    });
  }

  closeModal() {
    this.cleanModal();
    this.props.onCancel();
  }

  onChange(name, nameValid, value, valid) {
    let { experience } = this.state;

    this.setState({
      experience: { ...experience, [name]: value },
      [nameValid]: valid ? 'has-success' : 'has-danger'
    });
  }

  validate() {
    let title = this.title;
    let description = this.description;
    let surface = this.surface;
    let price = this.price;

    this.setState({
      [title.attributes.getNamedItem('namevalid').value]: title.validity.valid
        ? 'has-success'
        : 'has-danger',
      [description.attributes.getNamedItem('namevalid').value]: description
        .validity.valid
        ? 'has-success'
        : 'has-danger',
      [surface.attributes.getNamedItem('namevalid').value]: surface.validity
        .valid
        ? 'has-success'
        : 'has-danger',
      [price.attributes.getNamedItem('namevalid').value]: price.validity.valid
        ? 'has-success'
        : 'has-danger'
    });

    return (
      title.validity.valid &&
      description.validity.valid &&
      surface.validity.valid &&
      price.validity.valid
    );
  }

  onSaveExperience() {
    if (this.validate()) {
      let { experience } = this.state;
      this.cleanModal();
      this.props.onConfirm(experience);
    }
  }

  render() {
    let { experience } = this.state,
      { t } = this.props;

    return (
      <Modal
        size="md"
        isOpen={this.props.show}
        toggle={() => this.closeModal()}
      >
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.closeModal()}
        >
          {experience && experience._id
            ? t('Edit Experience')
            : t('New Experience')}
        </ModalHeader>
        <ModalBody>
          {experience ? (
            <Form className="form-horizontal">
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.titleValid
                    }
                  >
                    <Label>
                      <Trans>Title</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.title = node)}
                      namevalid="titleValid"
                      value={experience.title || ''}
                      required="required"
                      onChange={event =>
                        this.onChange(
                          'title',
                          'titleValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' +
                      this.state.descriptionValid
                    }
                  >
                    <Label>
                      <Trans>Description</Trans>
                    </Label>
                    <Input
                      type="textarea"
                      innerRef={node => (this.description = node)}
                      namevalid="descriptionValid"
                      value={experience.description || ''}
                      onChange={event =>
                        this.onChange(
                          'description',
                          'descriptionValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={6} sm={6} md={6} lg={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.surfaceValid
                    }
                  >
                    <Label>
                      <Trans>Surface</Trans>
                    </Label>
                    <Input
                      type="number"
                      innerRef={node => (this.surface = node)}
                      namevalid="surfaceValid"
                      value={experience.surface || ''}
                      step={0.01}
                      min={1}
                      required="required"
                      onChange={event =>
                        this.onChange(
                          'surface',
                          'surfaceValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.priceValid
                    }
                  >
                    <Label>
                      <Trans>Price</Trans>
                    </Label>
                    <Input
                      type="number"
                      innerRef={node => (this.price = node)}
                      namevalid="priceValid"
                      value={experience.price || ''}
                      step={0.01}
                      min={1}
                      required="required"
                      onChange={event =>
                        this.onChange(
                          'price',
                          'priceValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={() => this.closeModal()}>
            <Trans>Close</Trans>
          </Button>
          <Button color="info" onClick={() => this.onSaveExperience()}>
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(AchievementModal));
