import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import {
  AlertConfirm,
  PaginationCustom,
  PanelHeader,
  Spinner
} from '../../components';
import { allByUser, remove, save } from '../../helpers/actions/experiences';
import AchievementCard from './AchievementCard';
import AchievementModal from './AchievementModal';

class MyAchievements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      experiences: [],
      count: 0,
      page: 1,
      limit: 6,
      search: '',
      experience: null,
      experienceDelete: null
    };
  }

  load() {
    this.setState({ loading: true });

    let { page, limit, status, search } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    dispatch(allByUser({ limit, offset, status, search }))
      .then(({ data, meta }) =>
        this.setState({
          experiences: data,
          ...meta,
          loading: false
        })
      )
      .catch(() => this.setState({ loading: false }));
  }

  componentWillMount() {
    this.load();
  }

  onSave(experienceEdit) {
    this.setState({ loading: true, experience: null });

    let { dispatch } = this.props;

    dispatch(save(experienceEdit))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  deleteExperience(id) {
    this.setState({ loading: true, experienceDelete: null });

    let { dispatch } = this.props;

    dispatch(remove(id))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    let {
      loading,
      experiences,
      page,
      limit,
      count,
      experience,
      experienceDelete
    } = this.state;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Card>
            <CardHeader>
              <Row>
                <Col className={'text-left'} xs={12} md={4}>
                  <FormGroup>
                    <Label>
                      <Trans>Search</Trans>
                    </Label>
                    <Input
                      type="text"
                      value={this.state.search}
                      onChange={event =>
                        this.setState(
                          {
                            page: 1,
                            search: event.target.value
                          },
                          () => this.load()
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col className={'text-right'} xs={8} md={8}>
                  <Button
                    color="info"
                    onClick={() => this.setState({ experience: {} })}
                  >
                    <Trans>Add</Trans>
                  </Button>
                </Col>
              </Row>
            </CardHeader>
          </Card>

          {experiences.length > 0 ? (
            <div>
              <Row>
                {experiences.map(experience => (
                  <Col
                    key={experience._id}
                    xs={12}
                    md={6}
                    lg={4}
                    className="text-center equal-height"
                  >
                    <AchievementCard
                      experience={experience}
                      onEdit={() => this.setState({ experience })}
                      onDelete={() =>
                        this.setState({ experienceDelete: experience._id })
                      }
                    />
                  </Col>
                ))}
              </Row>
              <Row>
                <Col xs={12} className={'text-center'}>
                  <PaginationCustom
                    className="display-flex justify-content-center"
                    page={page}
                    count={count}
                    maxPerPage={limit}
                    onPageChange={page =>
                      this.setState({ page }, () => this.load())
                    }
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <Row>
              <Col xs={12} md={12}>
                <Card>
                  <CardBody>
                    <p className={'text-not-found text-center'}>
                      <Trans>No experiences found</Trans>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <AchievementModal
            show={!!experience}
            size={'lg'}
            experience={experience}
            onCancel={() => this.setState({ experience: false })}
            onConfirm={experienceEdit => this.onSave(experienceEdit)}
          />

          {!experienceDelete ? null : (
            <AlertConfirm
              message={'The experience cannot be recovered'}
              onCancel={() => this.setState({ experienceDelete: null })}
              onConfirm={() => this.deleteExperience(experienceDelete)}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(MyAchievements));
