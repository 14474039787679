import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Button, Card, CardBody, CardHeader, Row, Col } from 'reactstrap';
import { AlertConfirm, Spinner } from '../../components';
import Gallery from 'react-grid-gallery';
import { addPhotos, removePhotos } from '../../helpers/actions/experiences';

class Photos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      experienceId: null,
      photos: [],
      loading: false,
      photosDelete: false
    };
  }

  componentWillReceiveProps({ experience }) {
    if (experience && experience.photos) {
      let photos = experience.photos.map(photo => {
        return {
          id: photo._id,
          name: photo.name,
          src: photo.source,
          thumbnail: photo.source,
          thumbnailWidth: 1,
          thumbnailHeight: 1,
          isSelected: false
        };
      });

      this.setState({ experienceId: experience._id, photos });
    }
  }

  uploadClick() {
    let input = document.getElementById('uploadPhoto');
    input.click();
  }

  /* Resize image helper */
  resizeImage = function(settings) {
    let file = settings.file;
    let maxSize = settings.maxSize;
    let maxBytes = settings.maxBytes;
    let reader = new FileReader();
    let image = new Image();
    let reduce = 200;
    let canvas = document.createElement('canvas');

    let dataURItoBlob = function(dataURI) {
      let bytes =
        dataURI.split(',')[0].indexOf('base64') >= 0
          ? atob(dataURI.split(',')[1])
          : unescape(dataURI.split(',')[1]);
      let mime = dataURI
        .split(',')[0]
        .split(':')[1]
        .split(';')[0];
      let max = bytes.length;
      let ia = new Uint8Array(max);
      for (let i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
      let i = new Blob([ia], { type: mime });
      i['name'] = file.name;
      return new File([i], file.name);
    };

    let resize = function() {
      let width = image.width;
      let height = image.height;
      maxSize = width >= height ? width : height;
      maxSize = maxSize - reduce;
      reduce += 150;
      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width;
          width = maxSize;
        }
      } else {
        if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }
      }
      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d').drawImage(image, 0, 0, width, height);
      let dataUrl = canvas.toDataURL('image/jpeg');
      let f = dataURItoBlob(dataUrl);
      // only if image exceed settings size resize again
      if (f.size > maxBytes) {
        return resize();
      } else {
        return f;
      }
    };

    return new Promise(function(ok, no) {
      if (!file.type.match(/image.*/)) {
        no(new Error('Not an image'));
        return;
      }
      reader.onload = function(readerEvent) {
        image.onload = function() {
          return ok(resize());
        };
        image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  imageChange(e) {
    e.preventDefault();
    this.setState({ loading: true });

    let { experienceId } = this.state,
      { dispatch } = this.props;
    let files = e.target.files;
    let maxBytes = 999999;
    let result = [];

    let promises = [];

    for (let x = 0; x < files.length; x++) {
      if (files[x].size > maxBytes) {
        const config = {
          file: files[x],
          maxSize: 2048,
          maxBytes: maxBytes
        };
        promises.push(
          this.resizeImage(config).then(response => {
            result.push(response);
          })
        );
      } else {
        result.push(files[x]);
      }
    }

    Promise.all(promises)
      .then(() => {
        dispatch(addPhotos(experienceId, result))
          .then(() =>
            this.setState({ loading: false }, () => this.props.onConfirm())
          )
          .catch(() => this.setState({ loading: false }));
      })
      .catch(() => this.setState({ loading: false }));
  }

  selectImage(index) {
    let { photos } = this.state;

    photos[index].isSelected = !photos[index].isSelected;
    this.setState({ photos });
  }

  deleteImages() {
    this.setState({ loading: true });

    let { dispatch } = this.props;
    let { experienceId, photos } = this.state;
    let promises = [];

    if (photos) {
      photos.forEach(photo => {
        if (photo.isSelected) {
          promises.push(dispatch(removePhotos(experienceId, photo.id)));
        }
      });
    }

    Promise.all(promises)
      .then(() =>
        this.setState({ loading: false }, () => this.props.onConfirm())
      )
      .catch(() => this.setState({ loading: false }));
  }

  openImage(currentImage) {
    let url = currentImage.src;
    let win = window.open(url, '_blank');
    win.focus();
  }

  render() {
    let { loading, photos, photosDelete } = this.state;

    return (
      <div>
        <Card>
          {loading ? <Spinner /> : null}
          <CardHeader>
            <Row>
              <Col xs={12} md={4}>
                <h6>
                  <Trans>Images</Trans>
                </h6>
              </Col>
              <Col className="text-right" xs={12} md={8}>
                <input
                  type="file"
                  id="uploadPhoto"
                  name="uploadPhoto"
                  accept="image/png, image/jpeg"
                  multiple={true}
                  hidden={true}
                  onChange={event => this.imageChange(event)}
                />
                <Button
                  size="sm"
                  color="info"
                  onClick={() => this.uploadClick()}
                >
                  <Trans>Add</Trans>
                </Button>
                <Button
                  size="sm"
                  color="danger"
                  onClick={() => this.setState({ photosDelete: true })}
                >
                  <Trans>Delete</Trans>
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {photos.length > 0 ? (
              <Gallery
                images={photos}
                onSelectImage={index => this.selectImage(index)}
                onClickImage={event => this.openImage(event.target)}
                showLightboxThumbnails={true}
              />
            ) : (
              <p className={'text-not-found text-center'}>
                <Trans>No photos found</Trans>
              </p>
            )}
          </CardBody>
        </Card>

        {photosDelete ? (
          <AlertConfirm
            message={'The photos cannot be recovered'}
            onCancel={() => this.setState({ photosDelete: false })}
            onConfirm={() =>
              this.setState({ photosDelete: false }, () => this.deleteImages())
            }
          />
        ) : null}
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Photos));
