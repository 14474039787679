import React from 'react';
import { translate, Trans } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import defaultImage from '../../assets/img/image_placeholder.jpg';

class Step1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPiece: null
    };
  }

  onChange(event) {
    let selectedPiece = event.target.value;
    this.setState({ selectedPiece }, this.props.onChangePiece(selectedPiece));
  }

  render() {
    let { pieces } = this.props,
      { selectedPiece } = this.state;
    return (
      <div>
        <h5 className="info-text">
          <Trans>Select the piece</Trans>
        </h5>
        <Row className="justify-content-center">
          {pieces.map((piece, key) => {
            return (
              <Col key={key} xs={12} sm={4} md={4} lg={3}>
                <input
                  id={piece._id}
                  className="input-hidden"
                  type="radio"
                  name="radios"
                  value={piece._id}
                  checked={selectedPiece === piece._id}
                  onChange={event => this.onChange(event)}
                />
                <label htmlFor={piece._id}>
                  <img
                    src={piece.photo ? piece.photo : defaultImage}
                    alt={piece.name}
                  />
                  <h6 className={'text-center mt-1'}>{piece.name}</h6>
                </label>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
}

export default translate('translations-fr')(Step1);
