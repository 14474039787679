import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import get from 'lodash/get';

class AchievementInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      experience: {}
    };
  }

  componentWillReceiveProps({ experience }) {
    this.setState({ experience });
  }

  render() {
    let { experience } = this.state;

    return (
      <Card key={'assignClient'} className="card-flex user-data">
        <CardHeader className={'text-center'}>
          <h6>
            <Trans>Achievement Info</Trans>
          </h6>
        </CardHeader>
        <CardBody>
          <CardTitle tag="h5" className={'text-center'}>
            {get(experience, 'title', ' ')}
          </CardTitle>
          {experience ? (
            <div>
              <ul>
                <li>
                  <strong className={'mr-1'}>
                    <Trans>Surface</Trans>:
                  </strong>
                  {get(experience, 'surface', '')}
                </li>
                <li>
                  <strong className={'mr-1'}>
                    <Trans>Price</Trans>:
                  </strong>
                  {get(experience, 'price', '')}
                </li>
                <li>
                  <strong className={'mr-1'}>
                    <Trans>Description</Trans>:
                  </strong>
                  {get(experience, 'description', '')}
                </li>
              </ul>
            </div>
          ) : null}
        </CardBody>
      </Card>
    );
  }
}

export default connect()(translate('translations-fr')(AchievementInfo));
