import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import { PanelHeader, Spinner } from '../../components';
import { one } from '../../helpers/actions/projects';
import AssignClient from '../Projects/Client/AssignClient';
import AssignSupervisor from '../Projects/Supervisor/AssignSupervisor';
import Medias from '../Projects/Medias/Medias';
import Events from '../Projects/Events/Events';
import Documents from '../Projects/Documents/Documents';
import Notes from '../Projects/Notes/Notes';
import Board from '../Projects/Tasks/Board';
import DevisList from '../Projects/Devis/DevisList';
import OperationsProgress from '../Projects/Operations/OperationsProgress';
import Maps from '../Projects/Map3D/Maps';
import MapsPlans from '../Projects/MapsPlans';
import Chat from '../Projects/Chat';
import { setCookie } from '../../helpers/cookies';
import { UserData } from '../../helpers/api';
import { dateFormatter } from '../../helpers/formatters';
import queryString from 'query-string';

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      project: {
        operations: [],
        notes: [],
        medias: [],
        history: [],
        events: []
      },
      hTabs: 'notes',
      isOpen: false,
      history: null,
      user: null,
      params: {}
    };
  }

  getProject(projectId) {
    this.setState({ loading: true });
    let { dispatch } = this.props;

    dispatch(
      one(projectId, {
        include: ['client', 'supervisors', 'provider', 'devis'],
        fields: [
          'client',
          'pro',
          'type',
          'supervisors',
          'provider',
          'devis',
          'devisBase',
          'name',
          'status',
          'operations',
          'startDate',
          'endDate',
          'address',
          'plans',
          'created_at'
        ]
      })
    )
      .then(project => {
        let params = queryString.parse(this.props.location.search);
        this.setState({ project, loading: false, params });
      })
      .catch(() => this.setState({ loading: false }));
  }

  componentWillMount() {
    UserData()
      .then(({ user }) => {
        this.setState({ user: user._id }, () =>
          this.getProject(this.props.match.params.id)
        );
      })
      .catch(err => {});
  }

  componentWillUnmount() {
    setCookie(`project_pro_${this.props.match.params.id}`, Date.now());
  }

  render() {
    let { loading, project, hTabs, user, params } = this.state;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content" style={{ position: 'relative' }}>
          {loading ? <Spinner /> : null}
          <Row>
            <Col xs={12} className="">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={12} md={8} className="text-left">
                      <h5 className={'mb-1'}>
                        {project.name} - <Trans>{project.status}</Trans>
                      </h5>
                    </Col>
                    <Col xs={12} md={4} className="text-right">
                      <h5 className={'mb-1'}>
                        {dateFormatter(project.startDate, 'll')} -{' '}
                        {dateFormatter(project.endDate, 'll')}
                      </h5>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} className="equal-height mb-4">
              <AssignClient project={project} />
            </Col>
            <Col xs={12} md={6} className="equal-height mb-4">
              <AssignSupervisor project={project} />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Nav pills className="nav-pills-info project-pills">
                    <NavItem>
                      <NavLink
                        className={hTabs === 'notes' ? 'active' : ''}
                        onClick={() => this.setState({ hTabs: 'notes' })}
                      >
                        <Trans>Notes</Trans>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={hTabs === 'medias' ? 'active' : ''}
                        onClick={() => this.setState({ hTabs: 'medias' })}
                      >
                        <Trans>Medias</Trans>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={hTabs === 'documents' ? 'active' : ''}
                        onClick={() => this.setState({ hTabs: 'documents' })}
                      >
                        <Trans>Documents</Trans>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={hTabs === 'events' ? 'active' : ''}
                        onClick={() => this.setState({ hTabs: 'events' })}
                      >
                        <Trans>Events</Trans>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={hTabs === 'maps_plans' ? 'active' : ''}
                        onClick={() => this.setState({ hTabs: 'maps_plans' })}
                      >
                        <Trans>Maps Plans</Trans>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={hTabs === 'tasks' ? 'active' : ''}
                        onClick={() => this.setState({ hTabs: 'tasks' })}
                      >
                        <Trans>Tasks</Trans>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <hr />
                  <TabContent activeTab={hTabs}>
                    <TabPane tabId="medias">
                      {project && project._id ? (
                        <Medias
                          projectId={project._id}
                          created_at={project.created_at}
                        />
                      ) : null}
                    </TabPane>
                    <TabPane tabId="documents">
                      {project && project._id ? (
                        <Documents userId={user} projectId={project._id} />
                      ) : null}
                    </TabPane>
                    <TabPane tabId="notes">
                      {project && project._id ? (
                        <Notes
                          userId={user}
                          projectId={project._id}
                          created_at={project.created_at}
                        />
                      ) : null}
                    </TabPane>
                    <TabPane tabId="events">
                      {project && project._id ? (
                        <Events
                          userId={user}
                          projectId={project._id}
                          projAddress={project.address}
                        />
                      ) : null}
                    </TabPane>
                    <TabPane tabId="maps_plans">
                      {project && project._id ? (
                        <MapsPlans
                          projectId={project._id}
                          plans={project.plans}
                          documents={project.documents}
                          className={'card-plain user-data'}
                        />
                      ) : null}
                    </TabPane>
                    <TabPane tabId="tasks">
                      {project && project._id ? (
                        <Board projectId={project._id} />
                      ) : null}
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mb-4">
              {project && project._id ? (
                <DevisList
                  project={project}
                  basePath={'my-projects'}
                  user={user}
                />
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mb-4">
              {project && project._id ? (
                <OperationsProgress project={project} />
              ) : null}
            </Col>
          </Row>
          {project && project._id ? (
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Maps
                      projectId={project._id}
                      maps={project.maps}
                      className={'card-plain user-data'}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
          {project && project._id ? (
            <Chat
              open={params.focus && params.focus === 'chat' ? true : false}
              project={project}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Details));
