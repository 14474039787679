import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import ReactWizard from 'react-bootstrap-wizard';
import { all as allPieces } from '../../helpers/actions/pieces';
import { all as allSurfaces } from '../../helpers/actions/surfaces';
import { all } from '../../helpers/actions/packages';
import { getProPrice } from '../Projects/Devis/Prices';
import Step1 from './Step1.jsx';
import Step2 from './Step2.jsx';
import Step3 from './Step3.jsx';

class PackCatalogModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      selectedGroups: [],
      pieces: [],
      selectedPiece: null,
      packs: [],
      grouped: [],
      selectedPacks: []
    };
  }

  componentWillMount() {
    let { typeProject, dispatch } = this.props;
    let type = typeProject === 'compound-son' ? 'compound' : typeProject;

    Promise.all([
      dispatch(allSurfaces({ inPackage: true, type })),
      dispatch(allPieces({ inPackage: true, type }))
    ])
      .then(([groups, pieces]) =>
        this.setState({ groups: groups.data, pieces: pieces.data })
      )
      .catch(err => {});
  }

  cleanModal() {
    this.setState({
      packs: [],
      grouped: [],
      selectedPiece: null,
      selectedGroups: [],
      selectedPacks: []
    });
  }

  closeModal() {
    this.cleanModal();
    this.props.onCancel();
  }

  onChangePiece(selectedPiece) {
    this.setState({ selectedPiece }, () => this.getPackages());
  }

  onChangeGroups(selectedGroups) {
    this.setState({ selectedGroups }, () => this.getPackages());
  }

  onChangePacks(selectedPacks) {
    this.setState({ selectedPacks });
  }

  getPackages() {
    let { dispatch, typeProject } = this.props,
      { selectedPiece, selectedGroups } = this.state;
    let type = typeProject === 'compound-son' ? 'compound' : typeProject;

    if (selectedPiece && selectedGroups.length > 0) {
      dispatch(
        all({
          limit: 1000,
          piece: selectedPiece,
          group: selectedGroups,
          type
        })
      )
        .then(({ data }) => {
          let grouped = [];

          data.forEach(pack => {
            let index = grouped.findIndex(g => g.name === pack.group.name);
            if (index === -1) {
              grouped.push({
                name: pack.group.name,
                packs: [pack]
              });
            } else {
              grouped[index].packs.push(pack);
            }
          });
          this.setState({ grouped, packs: data, selectedPacks: [] });
        })
        .catch(err => {});
    } else this.setState({ packs: [], grouped: [], selectedPacks: [] });
  }

  handleFinishButton() {
    let { packs, selectedPacks } = this.state,
      { provider } = this.props;
    let operations = [];

    selectedPacks.forEach(select => {
      let pack = packs.find(p => p._id === select);

      if (pack) {
        pack.operations.forEach(operation => {
          let index = operations.findIndex(
            t =>
              t.name === operation.name &&
              t.group === pack.group.name &&
              t.piece === pack.piece.name
          );

          if (index === -1) {
            let proPrice = getProPrice(provider, operation.unit_price);
            operations.push({
              name: operation.name,
              unit: operation.unit,
              description: operation.description,
              group: pack.group.name,
              piece: pack.piece.name,
              unit_price: operation.unit_price,
              pro_price: proPrice,
              amount: 1,
              tva: provider && provider.tva ? provider.tva : 10,
              materials: operation.materials ? operation.materials : []
            });
          }
        });
      }
    });

    this.props.onConfirm(operations);
  }

  render() {
    let { pieces, groups, grouped, selectedPacks } = this.state,
      { t } = this.props;

    return (
      <Modal
        className={'package-catalog'}
        size={'lg'}
        isOpen={this.props.show}
        toggle={() => this.closeModal()}
      >
        <ModalBody className={'p-0'}>
          <ReactWizard
            steps={[
              {
                stepName: t('Pieces'),
                stepSize: 'sm',
                component: (
                  <Step1
                    pieces={pieces}
                    onChangePiece={selected => this.onChangePiece(selected)}
                  />
                )
              },
              {
                stepName: t('Surfaces'),
                stepSize: 'sm',
                component: (
                  <Step2
                    groups={groups}
                    onChangeGroups={selected => this.onChangeGroups(selected)}
                  />
                )
              },
              {
                stepName: t('Packages'),
                stepSize: 'sm',
                component: (
                  <Step3
                    grouped={grouped}
                    selectedPacks={selectedPacks}
                    onChangePacks={selected => this.onChangePacks(selected)}
                  />
                )
              }
            ]}
            navSteps
            validate={true}
            title={t('Add operations from package')}
            headerTextCenter
            previousButtonText={t('Previous')}
            nextButtonText={t('Next')}
            finishButtonText={t('Add')}
            finishButtonClick={() => this.handleFinishButton()}
            color="blue"
          />
        </ModalBody>
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(PackCatalogModal));
